import React from 'react';
import './styles.scss';

const PrepAirDeleteUser = () => {
  return (
    <div className={'container'} id={'prepair-delete-user'}>
      <h4>PrepAir Delete User Data</h4>
      <p>Please contact us at following to delete your PrepAir user data:</p>
      <ul>
        <li>
          <span>Email:</span>
          <a href="mailto:prepair.contact@gmail.com">
            prepair.contact@gmail.com
          </a>
        </li>
        <li>
          <span>Mobile NTC:</span>
          <a href="tel:+9779746308416">+9779746308416</a>
        </li>
        <li>
          <span>Mobile NCell:</span>
          <a href="tel:+9779804196096">+9779804196096</a>
        </li>
      </ul>
    </div>
  );
};
export default PrepAirDeleteUser;
