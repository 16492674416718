import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  Home,
  PrepAirDeleteUser,
  TermsAndConditions,
  PrivacyPolicy
} from '../Pages';
import { RouteNames } from '../Configs';

const Router = () => {
  return (
    <Switch>
      <Route exact path={`${RouteNames.HOME}`} component={Home} />
      <Route
        exact
        path={`${RouteNames.PREPAIR_DELETE_USER}`}
        component={PrepAirDeleteUser}
      />
      <Route
        exact
        path={`${RouteNames.TERMS_AND_CONDITIONS}`}
        component={TermsAndConditions}
      />
      <Route
        exact
        path={`${RouteNames.PRIVACY_POLICY}`}
        component={PrivacyPolicy}
      />
      <Redirect to={`${RouteNames.HOME}`} />
    </Switch>
  );
};

export default Router;
