import React from 'react';

import Banner from '../../Components/Banner';
import Vision from '../../Components/Vision';
import Upcoming from '../../Components/Upcoming';
// import Team from '../../Components/Team';
import {
  BANNER_SECTION,
  VISION_SECTION,
  UPCOMING_SECTION
  // TEAM_SECTION
} from '../../Configs/Constants';

const Home = () => {
  return (
    <div>
      <Banner {...BANNER_SECTION} />
      <Vision {...VISION_SECTION} />
      <Upcoming {...UPCOMING_SECTION} />
      {/* <Team {...TEAM_SECTION} /> */}
    </div>
  );
};

export default Home;
