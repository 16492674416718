import React from 'react';
import './styles.scss';

const TermsAndConditions = () => {
  return (
    <div className={'container'} id={'terms-and-conditions'}>
      <h4>Terms and Conditions</h4>
      <p>
        This terms and conditions will be applied to all users who use our
        ‘PrepAir’ application or ‘www.prepair.np’ website or product or services
        and any mode of registration i.e email, facebook id, phone number
        through any kind of electronic device.
      </p>
      <p>
        Please read the terms and condition along with privacy policy of our
        application/website/product/services carefully before using the
        application/website/services/products. If there any discrepancy between
        the terms and policies with respect to the
        application/website/product/services, these terms will prevail in any
        legal process.
      </p>
      <p>
        Your use/ access/ browsing of the application/website/products/services
        through any means shall signify that users has accepted the terms and
        conditions along with privacy policy and this agreement is enough to
        bound legally.
      </p>
      <p>
        And if you do not agree with the terms or privacy policy, please do not
        use the application/website/services/product.
        <ol type={'a'}>
          <li>
            When you register to use application/website/products/services from
            any electronic medium, you need to upload your photo, full name,
            address, email, phone number and password and you will become the
            valid user.
          </li>
          <li>
            Users need to read all terms and condition before clicking the
            button ‘ADD MONEY’ while adding money to the wallet or button
            ‘CONFIRM’ while activating the premium plan.
          </li>
          <li>
            When users click ‘ADD MONEY’ or ‘CONFIRM’ then it is considered that
            users had read all terms and condition of the application.
          </li>
          <li>
            Once the users load the fund to the wallet of PrepAir via any medium
            given in the wallet (Khalti, eSewa, connectIPS, mobile banking etc.)
            the loaded fund will not be refunded at any condition.
          </li>
          <li>
            The users are not allowed to request company to withdraw or refund
            the added amount in PrepAir.
          </li>
          <li>
            Cash given by the PrepAir as a bonus (referral or registration or
            any other activities) cannot be refunded or withdrawn but can be
            used for in-app purchases.
          </li>
          <li>
            Once the users got premium plan, the users cannot revert the plan
            and cannot claim or cannot request to the company for the refund or
            revert the plan.
          </li>
          <li>
            The Company shall have no responsibility for any loss or damage
            caused to tablet or any other hardware and / or software and/or
            instrument, including loss of data or effect on the processing
            speed, resulting from Your use of our products and services.
          </li>
          <li>
            Regarding the plans and events conducted by the PrepAir inside the
            product and services, clause (b), (c), (d), (e), (f), (g) will be
            applied.
            <ol type={'i'}>
              <li>
                If any paid live test or mock test got crashed during the event
                time then there would not be any refund but that test will
                rescheduled and users don’t need to pay again for the same test.
              </li>
            </ol>
          </li>
          <li>
            Participation in the referral program is voluntary and while
            referring users need to comply with the terms and condition of
            referral page. Any feedback you provide with respect to the
            Application shall be deemed to be non-confidential. The Application
            shall be free to use such information on an unrestricted basis.
            Further, by submitting the feedback, you represent and warrant that
            <ol type={'i'}>
              <li>
                Your feedback does not contain confidential or proprietary
                information of you or of third parties.
              </li>
              <li>
                The Company is not under any obligation of confidentiality,
                express or implied, with respect to the feedback
              </li>
              <li>
                The Application may have something similar to the feedback
                already under consideration or in development.
              </li>
              <li>
                You are not entitled to any compensation or reimbursement of any
                kind from the Company for the feedback under any circumstances,
                unless specified.
              </li>
            </ol>
          </li>
        </ol>
      </p>
      <p>Please exercise the caution while accessing our product.</p>
    </div>
  );
};
export default TermsAndConditions;
