import React from 'react';
import './styles.scss';
import { BasicProps } from '../../Configs';

const Banner: React.FC<BasicProps> = ({ head, title, content, image }) => {
  return (
    <div className={'banner container-section'}>
      <div className={'banner-img'}>
        <img src={image} alt="banner" />
      </div>
      <div className={'banner-content'}>
        <h2 className={'head'}> {head} </h2>
        <h4 className={'title'}> {title} </h4>
        <p> {content} </p>
      </div>
    </div>
  );
};

export default Banner;
