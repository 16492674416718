import React from 'react';
import './styles.scss';

const PrivacyPolicy = () => {
  return (
    <div className={'container'} id={'privacy-policy'}>
      <h4>Privacy Policy</h4>
      <p>
        Dear users your trust is valuable to us. To respect and protect your
        trust that users has shared with us PrepAir adheres ethical standard in
        gathering, using and safeguarding any information provided by our valued
        users and will be treated carefully. PrepAir operating under the T4
        Business Group is a leading Ed-Tech company incorporated in Nepal, for
        imparting proficiency. This privacy policy governs your use of the
        application ‘PerpAir’ (Application), www.prepair.np (Website) and
        services managed and provided by the company. Dear valid users, please
        read this terms and condition along with privacy policy carefully before
        using the application, website and services along with terms and
        condition. Your use/access/surfing of application, website and services
        in connection with application, website and services or registration
        with us through any modes or usage of any electronic device shall
        signify your acceptance of the policy and your agreement to be legally
        bounded. If you do not accept terms of this policy, kindly do not use
        the website, application or any our services in connection with this
        application.
      </p>
      <h4>Information Provided by Users</h4>
      <p>
        The application/website/services obtains the information that you
        provide when you register to access its services. On registration you
        generally provide us your name, email address, password, phone number
        and your image. Not only on registration you may provide your above
        mentioned information/data when you try to contact us or any information
        you may provide while using the application, websites or services. We
        have categorized these collected information as ‘sensitive personal
        information’ and protecting under the Article 28 of Constitution of
        Nepal 2072 i.e Right to Privacy which is the fundamental right of every
        citizens. And Individual Privacy Act 2018. Sometimes we service provider
        may use these information that you have provided at the time of use of
        our any services to contact you if necessary, to provide important
        information and notice, marketing promotions and events. And we may ask
        you further more personal information that identifies you personally or
        allows us to contact you. Sometimes we provide you an option not to
        provide the personal information or sensitive personal information which
        we are seeking from you. And while using the
        application/website/services you can use an option to withdraw your
        consent that has given to us earlier to use such personal information or
        sensitive personal information. To withdraw such consent users are
        required to send written application with a reason what makes you to
        feel unsecured at our details provided in this policy below. Company
        totally reserve the rights not to allow further usages of that
        application/website/services to those users.
      </p>
      <h4>Automatically Collected Data</h4>
      <p>
        Our application/website/services may collect certain information
        automatically in addition and that may be the type of mobile or computer
        device you use, unique device ID, IP address of your mobile/computer
        device, your mobile/computer operating system, internet browser you use
        and information about the way you use the application/website/services.
        And we may also collect other relevant information as per the
        information that you provide. Our Company do not share any kind of your
        sensitive personal information to those companies that we use for
        payment of our services.
      </p>
      <h4>Use of Your Personal Information</h4>
      <p>
        We service provider may use the collected information trends, to conduct
        research, to know users learning pattern, movements around the
        application/product/service and to gather demographic information and
        usage behavior about our users which can help us to improve our
        application/products/services so user can have better experience of it.
        We may use your data to provide your personalized content for better
        learning objectives. PrepAir do not sell, trade or rent your information
        to any third party unless users give authority to us by any medium.
        Sometimes we may need to provide aggregate statistics of our users,
        traffic patterns and related site information to government or any third
        reputable parties but this type of provided data will not contain any of
        your personally identifiable information. We will send emails, notices
        occasionally so that you don’t miss our events and may contact you to
        communicate about our application/product/services because users are
        considered as essential part of it.
      </p>
      <h4>Disclose Information</h4>
      <p>
        Sometimes we service provider need to disclose the information of users
        as per the demand of situation.
        <ul>
          <li>If it is required by law for legal process.</li>
          <li>
            To assist the investigation of potential violations of users rights
            while gathering information.
          </li>
          <li>
            In order to protect our rights, protect your safety, or the safety
            of others, to investigate fraud, address security or technical
            issues or to respond to a government request.
          </li>
          <li>
            To protect the rights of users from imminent harm, safety of the
            application/product/service/website as required or permitted by law.
          </li>
          <li>
            We may disclose information to third party service provider in order
            to personalize the application/website/product/services for analysis
            of users behavior and better users experience.
          </li>
          <li>
            Any portion of the information that may contain personal data
            relating to minors provided by you deemed to be given with the
            consent of the minors legal guardian by your registration with us.
          </li>
        </ul>
      </p>
      <h4>Access to Your Personal Information</h4>
      <p>
        After the submission of your information for registration, we will again
        provide the way to ensure that your personal information is updated and
        correct. We will give you an obvious way to change your profile from our
        application/product/website/service. We adopt some security measures in
        reasonable way which protect your password from being exposed or
        disclosed to any other.
      </p>
      <h4>Cookies</h4>
      <p>
        Cookies are usually small text files, given ID tags that are stored on
        your computer's browser directory or program data sub-folders. We send
        cookies to your mobiles, tablets, laptops, computers, thereby uniquely
        identifying your browser. It tracks your preferences, helps to login
        faster and aggregated to determine users trends. These data is used to
        provide more contents in areas of greater interest to a majority of
        users. If you inactivate cookies in your browser, you may miss our some
        features and services and may not function properly.
      </p>
      <h4>Alerts</h4>
      <p>
        We may send you alert by calls/sms/email to inform our users about our
        new services, events, offerings and other information that might be
        useful to you.
      </p>
      <h4>Public Forums</h4>
      <p>
        When you use our ‘PrepCom’ feature in our application or website by
        posting or sharing your personal information like comments, messages,
        photos will be available to all users and will be stored in public
        domain. All those sharing of information is done at your own risk.
        Please note that if you disclose your personal information in your
        profile or posting on our ‘PrepCom’ will be available publicly.
      </p>
      <h4>Security</h4>
      <p>
        We care about your information that we received during the process of
        registration. We maintain and safeguards your information through
        physical or electronic procedure. All the employees are not allowed to
        see your information and only limited with authorization can use it in
        order to operate, develop or improve our
        application/website/services/product.
      </p>
      <h4>Log Information</h4>
      <p>
        When you opens our website, our servers automatically record information
        that your browsing application sends whenever you visit a website. It
        may include your web request, internet protocol address, browser type,
        date and time of your request or more cookies that may uniquely identify
        your browsing application.
      </p>
      <h4>Users Communication</h4>
      <p>
        When you send an email or make any calls to us, we may record or store
        those communications to process your inquiries, respond your request and
        improve our services.
      </p>
      <h4>Changes to This Statement</h4>
      <p>
        When company gets further evolves, our privacy also need to evolve to
        overcome the new situations. We request you to review this policy
        regularly for any kind of changes and continuous use of it said to be
        approval of all changes.
      </p>
      <h4>Your Consent</h4>
      <p>
        We service provider believe that users connected with us through our
        application/website/product/service must ask an user for their consent
        to provide any information required for the use of our
        application/website/product/service. When you register, it is deemed
        that users had provided their consent expressly for our collection,
        processing, disclosing and handling of your information as mentioned in
        this policy now and amended by us. If you reside inside Nepal then your
        none of the information will be stored, transferred, shared to any other
        country. But if you reside outside the Nepal then your information will
        be transferred, stored and processed according to the applicable data
        protection law of Nepal.
      </p>
      <h4>Contact Information</h4>
      <p>
        Our users can contact us at anytime if any issues arises during the
        operation of our application/website/product/services through any
        communication medium.
        <br />
        <br />
        Email Address:&nbsp;
        <a href="mailto:prepair.contact@gmail.com">prepair.contact@gmail.com</a>
        <br />
        Address: Deep-16, Pokhara, Nepal
      </p>
    </div>
  );
};
export default PrivacyPolicy;
