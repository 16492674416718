import React from 'react';
import { Link } from 'react-scroll';
import './styles.scss';
import { SideBarProps } from '../../Configs';

const MenuBar: React.FC<SideBarProps> = ({ mainLogo, menuItem }) => {
  const [showSidebar, setShowSidebar] = React.useState(false);

  const handleSideBar = (value: boolean) => setShowSidebar(value);

  return (
    <>
      {!showSidebar ? (
        <div className={'float-right'} onClick={() => handleSideBar(true)}>
          <i className={'fa fa-bars side-bar-icon'}></i>
        </div>
      ) : (
        <div className={'side-bar'}>
          <div className={'menu-bar-down float-right'}>
            <div className={''}>
              <i
                className={'fa fa-times menu-cancel-icon float-right'}
                onClick={() => handleSideBar(false)}
              ></i>
            </div>
            <div className={'header-logo'}>
              <img
                src={mainLogo.src}
                alt={mainLogo.alt}
                onClick={() => handleSideBar(false)}
                style={{ height: 32, objectFit: 'contain' }}
              />
            </div>
            {menuItem.map(item => (
              <Link
                to={item.id}
                duration={500}
                smooth={true}
                offset={-80}
                onClick={() => handleSideBar(false)}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MenuBar;
