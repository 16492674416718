const mainLogo = require('../Assets/Images/Logo/logo.png');
const coprLogo = require('../Assets/Images/Logo/T4.png');
const appStore = require('../Assets/Images/Downloads/appStore.png');
const playStore = require('../Assets/Images/Downloads/playStore.png');

//Header Section
const MAIN_LOGO = {
  src: mainLogo.default,
  alt: 'T4 logo'
};
const MENU_ITEM = [
  {
    id: 'banner',
    name: 'Home'
  },
  {
    id: 'vision',
    name: 'Vision'
  },
  {
    id: 'up-coming',
    name: 'UpComing'
  }
  // {
  //   id: 'team',
  //   name: 'Team'
  // }
];
const STICKY = {
  offset: 90,
  browserWidth: 768
};
export const HEADER_SECTION = {
  mainLogo: MAIN_LOGO,
  menuItem: MENU_ITEM,
  sticky: STICKY
};

//Banner Section
export const BANNER_SECTION = {
  head: 'T4 Technology',
  title: 'Future of Technology',
  content: 'Connecting people of Nepal with technology.',
  image:
    'https://html.themexriver.com/Saasio/assets/img/d-agency/banner/ns1.png'
};

//Vision Section
export const VISION_SECTION = {
  head: 'Our Vision',
  title: 'Use of futuristic technologies.',
  content: 'React, React Native, Node, GraphQL, AWS, and Micro-services.',
  image:
    'https://html.themexriver.com/Saasio/assets/img/d-agency/service/s1.png'
};

//Upcoming Section
export const UPCOMING_SECTION = {
  head: 'Our Product',
  title: 'PrepAir',
  content:
    'PrepAir is entrance preparation partner. This application helps you to prepare for competitive exams.',
  image: 'https://html.themexriver.com/Saasio/assets/img/d-agency/about/ab1.png'
};

//Team Section
const TEAMS = [
  {
    id: 1,
    image: 'https://chsstm.github.io/cv/images/saimon.jpg',
    name: 'Saimon Thada',
    designation: 'Software Engineer',
    description:
      'Paraphraser contains an abundance of rarely used words/phrases and can paraphrase sentences in a variety of ways that are chosen randomly. Aside from this web based software being used as a paraphrasing tool or a text spinner',
    alt: 'designation image'
  },
  {
    id: 2,
    image:
      'https://html.themexriver.com/Saasio/assets/img/d-agency/team/tm3.png',
    name: 'Pushkar Gautam',
    designation: 'Software Engineer',
    description: 'Software being used as a paraphrasing tool or a text spinner',
    alt: 'designation image'
  },
  {
    id: 3,
    image: 'https://hispindia.org/Images/Team/sumit_poudel.jpg',
    name: 'Sumit Poudel',
    designation: 'Software Engineer',
    description:
      'Paraphraser contains an abundance of rarely used words/phrases and can paraphrase sentences in a variety of ways that are chosen randomly. Aside from this web based software being used as a paraphrasing tool or a text spinner',
    alt: 'designation image'
  }
];

export const TEAM_SECTION = {
  head: 'Our Team',
  title: 'here goes the title of the team',
  teams: TEAMS
};

//Footer Section
const FOOTER_CONTENT = {
  mainLogo: MAIN_LOGO,
  description: 'Lorem Ipsum is simply dummy text of the printing and type.'
};
const FOOTER_LINKS = MENU_ITEM;

const FOOTER_CONTACTS = {
  email: 't4echnology@gmail.com',
  phoneNumbers: ['+9779746308416', '+9779804196096'],
  opening: '24x7 Available',
  address: 'Deep Road, Bagar-Pokhara, Nepal',
  appStore: appStore.default,
  playStore: playStore.default
};

export const FOOTER_SECTION = {
  content: FOOTER_CONTENT,
  links: FOOTER_LINKS,
  contacts: FOOTER_CONTACTS,
  coprLogo: coprLogo.default
};

//Terms and Conditions
export const TERMSandCONDITION_SECTION = {
  head: 'Terms and Condition',
  tandc: 'content'
};
