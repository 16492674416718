import React from 'react';
import { Link, animateScroll } from 'react-scroll';
import './styles.scss';
import { MenuBarProps } from '../../Configs';

const MenuBar: React.FC<MenuBarProps> = React.memo(({ menuItem, scrolled }) => {
  return (
    <>
      <ul className={'menu-bar float-right'}>
        {menuItem.map(item => (
          <li key={item.id}>
            <Link to={item.id} duration={500} smooth={true} offset={-80}>
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
      {scrolled ? (
        <div className="scrollup" onClick={() => animateScroll.scrollToTop()}>
          <i className={'fa fa-angle-up'}></i>
        </div>
      ) : null}
    </>
  );
});

export default MenuBar;
