import React from 'react';
import './styles.scss';
import { BasicProps } from '../../Configs';

const Vision: React.FC<BasicProps> = ({ head, title, content, image }) => {
  return (
    <div className={'container container-section'} id={'vision'}>
      <div className={'row'}>
        <div className={'col-xs-12 col-md-6'}>
          <img alt={'vision'} src={image} className={'img-fluid'} />
        </div>
        <div className={'col-xs-12 col-md-6'}>
          <div className={'vision-content'}>
            <h2 className={'head'}> {head} </h2>
            <h4 className={'title'}> {title} </h4>
            <p> {content} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
