import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { FooterProps } from '../../Configs/TypeDefinition';

const Footer: React.FC<FooterProps> = ({
  content,
  links,
  contacts,
  coprLogo
}) => {
  return (
    <div className={'container'} id={'footer'}>
      <hr className={'featurette-divider'}></hr>
      <div className={'row'}>
        <div className={'col-md-4 col-xs-12 footer-content'}>
          <div className={'footer-logo'}>
            <Link to={'/'}>
              <img
                src={content.mainLogo.src}
                alt={content.mainLogo.alt}
                style={{ height: 32, objectFit: 'contain' }}
              />
            </Link>
          </div>
          {/* <p>{content.description}</p> */}
        </div>
        <div className={'col-md-4 col-xs-12 footer-links'}>
          <h3>Useful link:</h3>
          <ul>
            {links.map(({ id, name }) => (
              <li key={id}>
                <Link to={'/'}>{name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={'col-md-4 col-xs-12 footer-contacts'}>
          <h3>Contact:</h3>
          <h4>
            <i className={'fa fa-envelope'}></i>
            <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
          </h4>
          <h4>
            <i className={'fa fa-phone'}></i>
            {contacts.phoneNumbers.map(number => {
              return <a href={`tel:${contacts.email}`}>{number} &nbsp;</a>;
            })}
            <span>{contacts.opening}</span>
          </h4>
          <h4>
            <i className={'fa fa-globe'}></i>
            {contacts.address.includes('-') ? (
              <>
                {contacts.address.split('-')[0]}
                <span>{contacts.address.split('-')[1]}</span>
              </>
            ) : (
              contacts.address
            )}
          </h4>
          <div className={'download-btn'}>
            {/* <a href="#">
              <img src={contacts.appStore} alt={'App Store'} />
            </a> */}
            <a
              href="https://play.google.com/store/apps/details?id=com.tfour.prepair"
              target="_new"
            >
              <img src={contacts.playStore} alt={'Play Store'} />
            </a>
          </div>
          <div className="social-icons">
            <a href="https://www.facebook.com/prepair.np" target="_new">
              <span className="fa fa-facebook" />
            </a>
            {/* <a href="#" className="fa fa-twitter"></a> */}
            <a href="https://www.instagram.com/prepair.np" target="_new">
              <span className="fa fa-instagram" />
            </a>
            {/* <a href="#" className="fa fa-google"></a> */}
          </div>
        </div>
      </div>
      <hr className={'featurette-divider'}></hr>
      <div className={'row footer-copyright'}>
        <div className={'col'}>
          <p>
            © 2021
            <Link to={'/'}>
              <img src={coprLogo} alt={'T4'} height={20} width={20} />
            </Link>
          </p>
          <div className={'footer-copyright-links'}>
            <Link to={'/'}>Help</Link>
            <Link to={'/terms-and-conditions'}>Terms &amp; Condition</Link>
            <Link to={'/privacy-policy'}>Privacy</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
