import React, { useState, useEffect } from 'react';
import './styles.scss';
import MenuBar from '../MenuBar';
import SideBar from '../SideBar';
import { HeaderProps } from '../../Configs';
import { Link, useLocation } from 'react-router-dom';

const Header: React.FC<HeaderProps> = props => {
  const { mainLogo, menuItem, sticky } = props;

  const { pathname } = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [isMedium, setIsMedium] = useState(false);
  const [showMenuBar, setShowMenuBar] = useState(false);
  const navbarClasses = ['clearfix', 'header'];

  useEffect(() => {
    const handleWidth = () => {
      const width = window.innerWidth;
      if (width < sticky.browserWidth) setIsMedium(true);
      else setIsMedium(false);
    };

    handleWidth();
  }, [sticky.browserWidth]);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > sticky.offset) setScrolled(true);
      else setScrolled(false);
    };

    window.addEventListener('scroll', handleScroll);
  }, [sticky.offset]);

  useEffect(() => {
    if (pathname === '/') setShowMenuBar(true);
    else setShowMenuBar(false);
  }, [pathname]);

  if (scrolled) navbarClasses.push('header-sticky');

  return (
    <div className={navbarClasses.join(' ')}>
      <div className={'container'}>
        <div className={'col-xs-6 header-logo'}>
          <Link to={'/'}>
            <img
              src={mainLogo.src}
              alt={mainLogo.alt}
              style={{ height: 32, objectFit: 'contain' }}
            />
          </Link>
        </div>
        <div className={'col-xs-6'}>
          {showMenuBar ? (
            isMedium ? (
              <SideBar {...props} />
            ) : (
              <MenuBar {...{ menuItem, scrolled }} />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
