import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Main from '../Router';
import { HEADER_SECTION, FOOTER_SECTION } from '../Configs';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from '../Components/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Header {...HEADER_SECTION} />
        <Main />
        <Footer {...FOOTER_SECTION} />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
